import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ArrowLeftOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Divider, Image, Form, Typography, Modal, Badge, Switch } from 'antd'
import DOMPurify from 'dompurify'
import { Masonry } from 'masonic'
import { toast } from 'sonner'

import * as S from './styles'

import { useRolesStore } from '@/app'
import anymarketLogo from '@/assets/logo-anymarket.svg'
import vtexLogo from '@/assets/logo-vtex-single.svg'
import {
  ApiError,
  formatCurrency,
  formatDate,
  imgFallback,
  ModuleKey,
  RoleKey,
  toastError,
  useToggle,
  useRolePermissions,
} from '@/common'
import { ReturnButton } from '@/components'
import { TitleTabs } from '@/components/Layout/PageLayout'
import { CreateAgencyModal, SelectAgenciesModal } from '@/features/agency'
import {
  BGData,
  ImagePack,
  PublicationSummary,
  BGDataFormData,
  formatProductType,
  SkuFile,
  useUpdateBgDataMutation,
  useImagePackMutation,
  BgHistoryModal,
  defaultBGErrorMessages,
  ErrorRegisteringBGModal,
  FilterIcon,
  Showcase,
  BgStatusTags,
  UpdateBgDataMutation,
  ImagePackMutation,
  ProductPageSkeleton,
  ActionButtons,
  useChangeStatusMutation,
  DiscontinuedModal,
  type ProductDownloadType,
  bgQueries,
  BgFileManagementModal,
  EditGeneralInformationModal,
} from '@/features/BG'
import { PageLayout } from '@/layouts'
import { api } from '@/services'

export function ProductPage() {
  const { id: paramProductId } = useParams()

  const isSystemAdmin = useRolesStore((state) => state.isSystemAdmin)
  const commercialPermissions = useRolePermissions(ModuleKey.COMMERCIAL, RoleKey.COMMERCIAL)
  const ecommercePermissions = useRolePermissions(ModuleKey.ECOMMERCE, RoleKey.ECOMMERCE)

  const { data, isLoading } = useQuery(bgQueries.detail(paramProductId as string))

  const updateBgDataMutation = useUpdateBgDataMutation()
  const imagePackMutation = useImagePackMutation()
  // const publishVtexMutation = usePublishVtexMutation()

  const changeStatusMutation = useChangeStatusMutation()

  const [currentStep, setCurrentStep] = useState(0)
  const [isEditing, toggleEdit] = useToggle(false)
  const [isSelectingAgencies, toggleSelectAgencies] = useToggle(false)
  const [isCreatingAgency, toggleCreateAgency] = useToggle(false)
  const [isProductHistoryOpen, toggleProductHistory] = useToggle(false)
  const [isErrorModalOpen, toggleErrorModal] = useToggle(false)
  const [isDiscontinuedModalOpen, toggleDiscontinuedModal] = useToggle(false)
  const [isBgFilesModalOpen, toggleBgFilesModal] = useToggle(false)
  const [isEditGeneralInformationModalOpen, toggleEditGeneralInformationModal] = useToggle(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const [form] = Form.useForm<BGDataFormData>()
  const [formValues, setFormValues] = useState<BGDataFormData>({} as BGDataFormData)
  const [publishInAnymarket, setPublishInAnymarket] = useState(
    () => data?.integrate_with_anymarket || false,
  )

  useEffect(() => {
    if (data?.integrate_with_anymarket !== undefined) {
      setPublishInAnymarket(data.integrate_with_anymarket)
    }
  }, [data])

  const defaultValues = useMemo(
    () => ({
      productId: data?.vtex_id || paramProductId || '', // show vtex_id but is actually bgId
      productName: data?.name || data?.model || '',
      similarWords: data?.key_words || '',
      sku: data?.sku?.vtex_id || data?.sku?.id || '', // show vtex_id but is actually data.sku.id
      skuName: data?.sku?.name || '',
      partNumber: data?.ref_id || '',
      textLink: data?.link_id || '',
      pageTitle: data?.title || '',
      brand: 'Acer',
      category: data?.category_name || '',
      descriptionTitleTag: data?.meta_tag_description || '',
      descriptionTechnicalSheet: data?.description || '',
      filters: data?.filters || [],
      // filters: data?.specifications_vtex ? data?.specifications_vtex?.filters : [],
      icons: data?.icons || [],
      // icons: data?.specifications_vtex ? data?.specifications_vtex?.icons : [],
      showcase: (data?.sku?.sku_files || []) as SkuFile[],
      // showcase: [],
    }),
    [data, paramProductId],
  )

  const isMutating = updateBgDataMutation.isPending || imagePackMutation.isPending
  // || publishVtexMutation.isPending

  const steps = [
    {
      title: 'Dados do BG',
      content: (
        <BGData
          currentSelectedFilters={formValues.filters}
          currentSelectedIcons={formValues.icons}
          initialValues={{
            filters: data?.filters || [],
            icons: data?.icons || [],
          }}
          productType={{
            name: data?.product_type_name,
            vtex_id: data?.product_type_vtex_id,
          }}
          vtexCategoryId={data?.category_vtex_id as number}
        />
      ),
      disabled: currentStep === 0 || isMutating,
    },
    {
      title: 'Pack de Imagens',
      content: (
        <ImagePack
          data={
            formValues.showcase?.length > 0
              ? formValues.showcase
              : data?.sku?.sku_files
              ? (data?.sku?.sku_files as SkuFile[])
              : []
            // : (data?.sku.files as SkuFile[] | [])
          }
        />
      ),
      disabled: currentStep <= 1 || isMutating,
    },
    {
      title: 'Pendente de Publicação',
      content: <PublicationSummary data={formValues} />,
      disabled: currentStep <= 2 || isMutating,
    },
  ]

  const productTabItems = [
    {
      key: 'bg-details',
      label: 'Detalhes do BG',
      children: (
        <S.ProductSpecsContainer>
          {data?.technical_specification ? (
            <div>
              <S.ExternalCharacCard>
                <strong>Características Externas:</strong>
                <S.WrapingText
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      !data?.external_characteristics ||
                        data?.external_characteristics === '<p></p>'
                        ? '-'
                        : data?.external_characteristics,
                    ),
                  }}
                />
              </S.ExternalCharacCard>

              <Masonry
                items={Object.entries(data?.technical_specification)}
                render={(data) => {
                  const key = data.data[0]
                  const values = data.data[1]

                  return (
                    <S.Card key={key}>
                      <strong>{key}:</strong>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(String(values.length > 0 ? values : '-')),
                        }}
                      />
                    </S.Card>
                  )
                }}
                rowGutter={12}
                columnGutter={16}
                maxColumnCount={2}
                overscanBy={10}
              />
            </div>
          ) : (
            <S.ErrorContainer>
              <S.ExclamationIcon />
              <Typography>
                Ops, tivemos um problema ao carregar este painel. Por favor, recarregue a página!
              </Typography>
            </S.ErrorContainer>
          )}

          {(data?.sku?.sku_files?.length as number) > 1 && (
            <S.PackImageTitle>Pack de Imagens</S.PackImageTitle>
          )}

          <S.ImageViewer>
            {data?.sku?.sku_files?.map((src, index: number) => {
              if (index === 0) {
                return null
              }

              return (
                <div key={index}>
                  <Image
                    height={164}
                    width={164}
                    key={index}
                    src={src.filename_url}
                    preview={false}
                    style={{
                      objectFit: 'contain',
                      borderRadius: 6,
                      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
                    }}
                  />
                </div>
              )
            })}
          </S.ImageViewer>
        </S.ProductSpecsContainer>
      ),
    },
    {
      key: 'publication-details',
      label: 'Detalhes da Publicação',
      children: (
        <S.ProductSpecsContainer>
          <PublicationSummary data={defaultValues} />
        </S.ProductSpecsContainer>
      ),
    },
  ]

  const tabItems = productTabItems.filter((item) => {
    const hasTabAccess =
      isSystemAdmin ||
      ecommercePermissions?.isAdmin ||
      ecommercePermissions?.canRead ||
      commercialPermissions?.isAdmin ||
      commercialPermissions?.canRead

    const shouldShowPublicationDetails =
      hasTabAccess && (data?.status === 'PENDENTE DE PRECIFICAÇÃO' || data?.status === 'PUBLICADO')

    if (!shouldShowPublicationDetails && item.key === 'publication-details') {
      return item.key !== 'publication-details'
    }

    return item
  })

  async function handleDownloadFiles(type: ProductDownloadType) {
    const toastId = toast.loading('Preparando download...')

    setIsDownloading(true)

    const ENDPOINTS = {
      docx: `/products/${paramProductId}/specification_docx_url`,
      bgFiles: `/products/support_files/zip/${paramProductId}`,
    }

    const SUCCESS_MESSAGES = {
      docx: 'Download do docx concluído',
      bgFiles: 'Download dos arquivos concluído',
    }

    const DOWNLOAD_NAMES = {
      docx: 'ficha_tecnica.docx',
      bgFiles: 'arquivos_bg.zip',
    }

    try {
      const { data } = await api.get<{ url: string; url_download: string }>(ENDPOINTS[type])

      if (!data.url && !data.url_download) {
        console.error('Download error: URL not found')
        return
      }

      const downloadLink = document.createElement('a', { is: 'download-link' })

      downloadLink.href = data.url || data.url_download
      downloadLink.setAttribute('download', DOWNLOAD_NAMES[type])
      downloadLink.download = DOWNLOAD_NAMES[type]
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)

      toast.success(SUCCESS_MESSAGES[type], {
        id: toastId,
      })
    } catch (err) {
      console.error('Download error:', err)
      toast.error('Falha no download', {
        id: toastId,
      })
    } finally {
      setIsDownloading(false)
    }
  }

  function startEdit(isEditing = false) {
    return Modal.confirm({
      title: (
        <span>
          {isEditing ? (
            <>
              Deseja editar o produto <strong>{data?.ref_id}</strong> ?
            </>
          ) : (
            <>
              Deseja Iniciar as etapas de integração do produto <strong>{data?.ref_id}</strong> ?
            </>
          )}
        </span>
      ),
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FF8B27' }} />,
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: () => toggleEdit(),
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        type: 'link',
        style: {
          color: '#80c343',
        },
      },
    })
  }

  function handleCancelEdit() {
    setCurrentStep(0)
    form.resetFields()
    toggleEdit()
  }

  function cancelErrorModal() {
    handleCancelEdit()
    toggleErrorModal()
  }

  function retryErrorModal() {
    handlePublishProduct()
    toggleErrorModal()
  }

  function updateFormAndAdvanceStep(formValues: BGDataFormData) {
    setFormValues(formValues)
    setCurrentStep((currentStep) => currentStep + 1)
  }

  function getFormValues() {
    let values = form.getFieldsValue()

    // check if values object is empty, if so, use formValues
    if (Object.keys(values).length === 0) {
      values = formValues
    }

    const bgDataFormValues: BGDataFormData = {
      ...values,
      filters: form.getFieldValue('filters').filter((item: FilterIcon) => item.values.length > 0), // remove empty values
      icons: form.getFieldValue('icons').filter((item: FilterIcon) => item.values.length > 0), // remove empty values
      showcase: form.getFieldValue('showcase'),
    }

    return bgDataFormValues
  }

  async function validateForm() {
    try {
      await form.validateFields() // validate fields in step 0

      // If it's not the ImagePack step or showcase is valid return true
      if (currentStep !== 1 || validateImagePackStep()) return true
    } catch (error: any) {
      console.error('ERROR', error)

      if (error.errorFields && error.errorFields.length > 0) {
        toast.error('Preencha todos os campos obrigatórios')
      }
    }

    return false
  }

  function validateImagePackStep() {
    const showcaseValues: Showcase[] = form.getFieldValue('showcase')
    const mainShowcaseItem = showcaseValues.find((item) => item.main === true)

    if (!showcaseValues || !mainShowcaseItem) {
      toast.error('Selecione uma vitrine para continuar')
      return false
    }

    return true
  }

  async function handleNextStep() {
    try {
      const isFormValid = await validateForm()

      if (!isFormValid) return

      const isImagePackStep = currentStep === 1

      if (isImagePackStep && !validateImagePackStep()) return

      updateFormAndAdvanceStep(getFormValues())
    } catch (e) {
      console.error(e)
    }

    //scroll to top
    window.scrollTo(0, 0)
  }

  async function handleUpdateProductData(updateProductPayload: UpdateBgDataMutation) {
    return updateBgDataMutation.mutateAsync(updateProductPayload)
  }

  async function handleUpdateImagePack(imagePackPayload: ImagePackMutation) {
    return imagePackMutation.mutateAsync(imagePackPayload)
  }

  async function handlePublishProductOperations(
    productValues: any,
    saveAsDraft?: { shouldSave: boolean; draftStep: 'productData' | 'imagePack' },
  ) {
    const categoryId =
      productValues.category === data?.category_name ? data?.category_id : productValues.category

    const updateProductPayload = {
      productId: paramProductId as string,
      brand_id: data?.brand_id as number,
      description: productValues.descriptionTechnicalSheet,
      key_words: productValues.similarWords,
      sku_id: data?.sku.id as string,
      sku_name: productValues.skuName || data?.sku.name,
      text_link: productValues.textLink,
      name: productValues.productName,
      title: productValues.pageTitle,
      meta_tag_description: productValues.descriptionTitleTag,
      part_number: productValues.partNumber,
      filters: productValues.filters,
      icons: productValues.icons,
      category_id: String(categoryId),
      integrate_with_anymarket: publishInAnymarket,
    }

    await handleUpdateProductData(updateProductPayload)

    if (saveAsDraft?.shouldSave && saveAsDraft?.draftStep === 'productData') {
      return
    }

    const imagePackPayload = {
      productId: paramProductId as string,
      skuId: data?.sku.id as string,
      imagePack: productValues.showcase.map((field: any, index: number) => ({
        name: field.name,
        main: field.main,
        order: index,
      })),
      saveAsDraft: saveAsDraft?.shouldSave || false,
    }

    await handleUpdateImagePack(imagePackPayload)
  }

  async function handleSaveDraft() {
    const isValid = await validateForm()

    if (!isValid) {
      toast.error('Preencha todos os campos obrigatórios')
      return
    }

    const productValues = getFormValues()

    try {
      const draftStep = currentStep === 0 ? 'productData' : 'imagePack'

      const saveAsDraftPromise = handlePublishProductOperations(productValues, {
        shouldSave: true,
        draftStep,
      })

      toast.promise(saveAsDraftPromise, {
        loading: 'Salvando rascunho...',
        success: (
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              Rascunho <span style={{ fontWeight: 'bold' }}>salvo</span> com{' '}
              <span style={{ fontWeight: 'bold' }}>sucesso</span>!
            </div>
          </S.ToastContainer>
        ),
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            defaultMessage: 'Não foi possível salvar o rascunho.',
            errorMessages: defaultBGErrorMessages,
          }),
      })
    } catch (err) {
      console.error('Error saving draft', err)
    }
  }

  async function handlePublishProduct() {
    const productValues = getFormValues()

    try {
      const updateProductPromise = handlePublishProductOperations(productValues)

      toast.promise(updateProductPromise, {
        loading: 'Integrando...',
        success: (
          <S.ToastContainer>
            <S.CircleOutlined />

            {data?.status === 'EM RASCUNHO NO E-COMMERCE' ? (
              <div>
                Produto <span style={{ fontWeight: 'bold' }}>enviado para precificação</span>!
              </div>
            ) : (
              <div>
                Produto <span style={{ fontWeight: 'bold' }}>editado</span> com sucesso!
              </div>
            )}
          </S.ToastContainer>
        ),
        className: 'toast-sucess',
        error: (error: ApiError) => {
          const defaultMessge =
            data?.status === 'EM RASCUNHO NO E-COMMERCE'
              ? 'Não foi possível enviar o produto para precificação.'
              : 'Falha ao editar produto'

          return toastError({
            error,
            defaultMessage: defaultMessge,
            errorMessages: defaultBGErrorMessages,
          })
        },
      })

      await updateProductPromise

      handleCancelEdit()
    } catch (err) {
      console.error('Error publishing product', err)
      toggleErrorModal()
    }
  }

  function handleChangeProductPublicationStatus(newStatus: boolean) {
    const promise = changeStatusMutation.mutateAsync({
      product_id: paramProductId as string,
      status: newStatus,
    })

    toast.promise(promise, {
      loading: 'Alterando status...',
      success: (
        <S.ToastContainer>
          <S.CircleOutlined />

          <div>
            Produto{' '}
            <span style={{ fontWeight: 'bold' }}>
              {newStatus === true ? 'publicado' : 'despublicado'}
            </span>{' '}
            com <span style={{ fontWeight: 'bold' }}>sucesso</span>!
          </div>
        </S.ToastContainer>
      ),
      className: 'toast-sucess',
      error: `Falha ao ${
        newStatus === true ? 'publicar' : 'despublicar'
      } produto. Tente novamente mais tarde.`,
    })
  }

  function confirmChangePublicationStatus(newStatus: boolean) {
    Modal.confirm({
      title: `${newStatus === true ? 'Publicar' : 'Despublicar'} o produto?`,
      content: (
        <span>
          O novo status do produto será:{' '}
          <strong>{newStatus === true ? 'Publicado' : 'Despublicado'}</strong>.
        </span>
      ),
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FAAD14' }} />,
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => handleChangeProductPublicationStatus(newStatus),
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        danger: true,
      },
    })
  }

  const BackButton = () => {
    return (
      <>
        {!isEditing && <ReturnButton />}

        {isEditing && (
          <>
            {currentStep <= 0 ? (
              <S.BackButton type="link" icon={<ArrowLeftOutlined />} onClick={handleCancelEdit}>
                Voltar
              </S.BackButton>
            ) : (
              <S.BackButton
                type="link"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  setCurrentStep((currentStep) => currentStep - 1)
                }}
              >
                Voltar
              </S.BackButton>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <PageLayout title="BGView">
      {isBgFilesModalOpen && (
        <BgFileManagementModal
          isOpen={isBgFilesModalOpen}
          onClose={toggleBgFilesModal}
          productId={data?.id as string}
          handleDownloadFiles={handleDownloadFiles}
          isDownloading={isDownloading}
        />
      )}

      {isProductHistoryOpen && (
        <BgHistoryModal
          isOpen={isProductHistoryOpen}
          onClose={toggleProductHistory}
          productId={data?.id as string}
        />
      )}

      {isSelectingAgencies && (
        <SelectAgenciesModal
          productId={paramProductId as string}
          isOpen={isSelectingAgencies}
          onClose={toggleSelectAgencies}
          toggleCreateAgency={toggleCreateAgency}
        />
      )}

      {isCreatingAgency && (
        <CreateAgencyModal isOpen={isCreatingAgency} onClose={toggleCreateAgency} />
      )}

      {isErrorModalOpen && (
        <ErrorRegisteringBGModal
          isOpen={isErrorModalOpen}
          onClose={cancelErrorModal}
          onConfirm={retryErrorModal}
          bgSku={data?.ref_id || ''}
        />
      )}

      {isDiscontinuedModalOpen && (
        <DiscontinuedModal
          isOpen={isDiscontinuedModalOpen}
          onClose={toggleDiscontinuedModal}
          data={data}
        />
      )}

      {isEditGeneralInformationModalOpen && (
        <EditGeneralInformationModal
          productData={data}
          isOpen={isEditGeneralInformationModalOpen}
          onClose={toggleEditGeneralInformationModal}
        />
      )}

      <S.Container>
        <S.Header>
          <S.ButtonsContainer>
            <BackButton />

            {!isLoading && (
              <S.ActionButtonsContainer>
                <ActionButtons
                  productId={paramProductId as string}
                  status={data?.status}
                  isEditing={isEditing}
                  currentStep={currentStep}
                  isLoading={updateBgDataMutation.isPending || imagePackMutation.isPending}
                  handleStartEditing={startEdit}
                  toggleProductHistory={toggleProductHistory}
                  toggleProductDiscontinued={toggleDiscontinuedModal}
                  toggleAgenciesSelect={toggleSelectAgencies}
                  toggleBgFiles={toggleBgFilesModal}
                  toggleEditGeneralInformation={toggleEditGeneralInformationModal}
                  handleNextStep={handleNextStep}
                  handleSaveDraft={handleSaveDraft}
                  handlePublishProduct={handlePublishProduct}
                />
              </S.ActionButtonsContainer>
            )}
          </S.ButtonsContainer>

          <S.StepsContainer>
            {!isLoading && !isEditing && (
              <S.Date>
                Atualizado em{' '}
                <strong>
                  {formatDate({ date: data?.created_at as string, dateFormat: 'DD/MM/YYYY' })}
                </strong>
              </S.Date>
            )}

            <S.Title>
              <span>Visualização do produto</span>

              <Divider type="vertical" style={{ height: 'auto' }} />

              <strong>{data?.ref_id || ''}</strong>
            </S.Title>

            <S.Subtitle>SKU: {data && data?.sku?.vtex_id ? data?.sku?.vtex_id : '--'}</S.Subtitle>

            {isEditing && (
              <S.Steps
                current={currentStep}
                items={steps}
                onChange={(step) => setCurrentStep(step)}
              />
            )}
          </S.StepsContainer>

          {(data?.status === 'PUBLICADO' || data?.status === 'DESPUBLICADO') && (
            <S.PriceContainer>
              {data?.sku?.price ? (
                <>
                  <S.Price>
                    <Image src={vtexLogo} width={30} alt="Logo VTEX" preview={false} />

                    <span>
                      {formatCurrency({
                        value: data?.sku.price,
                        type: 'currency',
                      })}
                    </span>
                  </S.Price>
                  <S.Stock>
                    Em estoque:
                    <S.StockValue $isLowStock={data?.sku?.low_stock_vtex}>
                      {data?.sku?.stock_quantity_vtex}
                    </S.StockValue>
                  </S.Stock>
                </>
              ) : null}

              <S.PriceDivider type="vertical" />
              {data?.sku?.price_anymarket ? (
                <>
                  <S.Price>
                    <Image src={anymarketLogo} width={40} alt="Logo AnyMarket" preview={false} />

                    <span>
                      {formatCurrency({ value: data?.sku?.price_anymarket, type: 'currency' })}
                    </span>
                  </S.Price>
                  <S.Stock>
                    Em estoque:
                    <S.StockValue $isLowStock={data?.sku?.low_stock_anymarket}>
                      {data?.sku?.stock_quantity_anymarket}
                    </S.StockValue>
                  </S.Stock>
                </>
              ) : null}
            </S.PriceContainer>
          )}
        </S.Header>

        {isLoading ? (
          <ProductPageSkeleton />
        ) : (
          <>
            <S.BgProductInfoContainer>
              <S.BGInfoContainer>
                <S.imageContainer>
                  <Image
                    height={274}
                    width={274}
                    preview={false}
                    fallback={imgFallback}
                    alt={`Hero image de ${data?.model}`}
                    src={data?.sku?.sku_files?.[0]?.filename_url}
                    style={{ objectFit: 'contain', borderRadius: 6 }}
                  />
                </S.imageContainer>

                <S.InfoContainer>
                  <S.SummaryContainer>
                    <S.StatusContainer>
                      <S.StatusContent>
                        <BgStatusTags showDescription status={data?.status} isBgView />

                        {data?.status === 'PENDENTE DE PRECIFICAÇÃO' && (
                          <Badge count="Não publicado" />
                        )}
                      </S.StatusContent>

                      {!isEditing &&
                        (isSystemAdmin ||
                          ecommercePermissions?.isAdmin ||
                          ecommercePermissions?.canUpdate) &&
                        (data?.status === 'PUBLICADO' || data?.status === 'DESPUBLICADO') && (
                          <Switch
                            disabled={changeStatusMutation.isPending}
                            checked={data?.status === 'PUBLICADO'}
                            onChange={(newStatus) => confirmChangePublicationStatus(newStatus)}
                            checkedChildren={'Publicado'}
                            unCheckedChildren={'Despublicado'}
                          />
                        )}

                      {isEditing && currentStep === 2 && (
                        <S.AnymarketPublishContainer>
                          <span>
                            Integrar também com <strong>AnyMarket</strong>
                          </span>

                          <Switch
                            checkedChildren="Sim"
                            unCheckedChildren="Não"
                            value={publishInAnymarket}
                            onChange={(value) => setPublishInAnymarket(value)}
                          />
                        </S.AnymarketPublishContainer>
                      )}
                    </S.StatusContainer>

                    <S.ProductCategory>
                      {formatProductType(data?.product_type_name as string) ||
                        data?.product_type_name}

                      <Divider type="vertical" />

                      <strong>{data?.category_name}</strong>
                    </S.ProductCategory>

                    <S.DisplayName>{data?.name || data?.model}</S.DisplayName>

                    <S.Summary>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data?.overview as string),
                        }}
                      />
                    </S.Summary>
                  </S.SummaryContainer>
                </S.InfoContainer>
              </S.BGInfoContainer>

              {!isEditing ? (
                <TitleTabs items={tabItems} defaultActiveKey="bg-details" />
              ) : (
                <Form
                  form={form}
                  layout="vertical"
                  validateTrigger="onChange"
                  initialValues={{ ...defaultValues }}
                >
                  <S.Content>{steps[currentStep]?.content}</S.Content>
                </Form>
              )}
            </S.BgProductInfoContainer>

            <S.FooterButtonContainer>
              <BackButton />

              <S.ActionButtonsContainer>
                <ActionButtons
                  productId={paramProductId as string}
                  status={data?.status}
                  isEditing={isEditing}
                  currentStep={currentStep}
                  isLoading={updateBgDataMutation.isPending || imagePackMutation.isPending}
                  handleStartEditing={startEdit}
                  toggleProductHistory={toggleProductHistory}
                  toggleProductDiscontinued={toggleDiscontinuedModal}
                  toggleAgenciesSelect={toggleSelectAgencies}
                  toggleBgFiles={toggleBgFilesModal}
                  toggleEditGeneralInformation={toggleEditGeneralInformationModal}
                  handleNextStep={handleNextStep}
                  handleSaveDraft={handleSaveDraft}
                  handlePublishProduct={handlePublishProduct}
                />
              </S.ActionButtonsContainer>
            </S.FooterButtonContainer>
          </>
        )}
      </S.Container>
    </PageLayout>
  )
}
